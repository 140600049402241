import request from "@/utils/axiosReq";

const baseURL = `${import.meta.env.VITE_APP_BASE_API}${
  import.meta.env.VITE_APP_PLATFORM_INSPECT_API
}`;

export function getAmrBySnCode(query) {
  return request({
    baseURL,
    url: "/inspect/amr/getBySnCode",
    method: "get",
    data: query,
  });
}

export function videoPushChange(query) {
  return request({
    baseURL,
    url: "/inspect/amr/videoPushSwitch",
    method: "post",
    data: query,
  });
}

/**
 * @description:获取警报记录列表
 * @param {*} query
 * @return {*}
 */
export function getAlarmRecordList(query) {
  return request({
    baseURL,
    url: "/warning/list",
    method: "post",
    data: query,
  });
}

/**
 * @description: 解除警报
 * @param {*} query
 * @return {*}
 */
export function relieveAlarm(query) {
  return request({
    baseURL,
    url: "/warning/relieve",
    method: "post",
    data: query,
  });
}

// 获取基础配置
export function getBaseConfig(query) {
  return request({
    baseURL,
    url: "/basicConfig/loadBySnCode",
    method: "get",
    data: query,
  });
}

export function getAlarmConfig(query) {
  return request({
    baseURL,
    url: "/warningConfig/loadBySnCode",
    method: "get",
    data: query,
  });
}

export function saveBaseConfig(query) {
  return request({
    baseURL,
    url: "/basicConfig/save",
    method: "post",
    data: query,
  });
}

/**
 * @description:警报配置批量下发
 * @param {*} query
 * @return {*}
 */
export function issueAlarmConfig(query) {
  return request({
    baseURL,
    url: "/warningConfig/saveChanged",
    method: "post",
    data: query,
  });
}

/**
 * @description:获取音频下拉列表
 * @param {*} query
 * @return {*}
 */
export function getAudioList(query) {
  return request({
    baseURL,
    url: "/voice/list",
    method: "get",
    data: query,
  });
}

/**
 * @description: 加载基础配置
 * @param {*} query
 * @return {*}
 */
export function getBaseConfigBySnCode(query) {
  return request({
    baseURL,
    url: "/basicConfig/loadBySnCode",
    method: "get",
    data: query,
  });
}

/**
 * @description:根据sncode设置雨刷器
 * @param {*} query
 * @return {*}
 */
export function setGearBySnCode(query) {
  return request({
    baseURL,
    url: "/basicConfig/setGearBySnCode",
    method: "get",
    data: query,
  });
}

/**
 * @description: 获取巡检警报类型列表
 * @param {*} data
 * @return {*}
 */
export function apiGetWarningTypeList(data) {
  return request({
    url: "/inspect/warningConfig/getWarningTypeList",
    data,
    method: "get",
  });
}
